import * as React from "react";
import { FormEvent, useRef } from "react";
import axios from "axios";

export default function Rebalance() {
  const apiRef = useRef<HTMLInputElement>(null);
  const handleSubmit = (event : FormEvent) => {
    event.preventDefault()
    if (apiRef.current !== null) {
      console.log(apiRef.current.value)
      try {
          axios.post("/rebalancing", { apiKey: apiRef.current.value })
              .then((response) => {
                  if (response.status === 200) {
                      alert('Success')
                  } else {
                      alert(`FailedResult: ${JSON.stringify(response)}`)
                  }
              })
              .catch((error) => {
                alert(`Failed to rebalance: ${JSON.stringify(error.response.data)}`)
              });
      } catch (error) {
          console.log(`Axios call failed`, error)
          alert(`FailedResult: ${JSON.stringify(error)}`)
      }

    }
  }

  return (
    <div>
      <h2>Rebalance</h2>
      <form onSubmit={handleSubmit}>
        <label>API Key</label>
        <input ref={apiRef} type="text"></input>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
}
