import axios, { AxiosResponse } from 'axios'
import {InvestmentReturn} from "./types";

async function sendGetRequest(
  url: string,
  params?: any,
): Promise<AxiosResponse | null> {
  for (let i = 0; i < 3; i++) {
    try {
      return await axios.get(url, {
        ...params,
        timeout: 5000,
      })
    } catch (error) {
      console.log(`Axios call failed`, error)
    }
  }
  return null
}

async function sendPostRequest(
  url: string,
  payload: {}
): Promise<AxiosResponse | null> {
  for (let i = 0; i < 3; i++) {
    try {
      return await axios.post(url, payload)
    } catch (error) {
      console.log(`Axios call failed`, error)
    }
  }
  return null
}

export async function fetchPerfData(): Promise<InvestmentReturn | null> {
  const urlParams = new URL(window.location.href).searchParams
  const startTimestamp = getTimeStamp(urlParams.get('start'))
  const endTimestamp = getTimeStamp(urlParams.get('end'))
  const path = buildPath(startTimestamp, endTimestamp)
  const result = await sendGetRequest(path)
  return result ? result.data : null
}

function getTimeStamp(timeString: string|null) {
  if (!timeString) {
    return null
  }
  const date = new Date(Date.parse(timeString))
  return date.getTime()
}

function buildPath(startTimestamp: number|null, endTimestamp: number|null) {
  let path = '/performance'

  if (startTimestamp) {
    path += '/?start=' + startTimestamp
    if (endTimestamp) {
      path += '&end=' + endTimestamp
    }
  }
  return path
}

