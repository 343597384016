import React from 'react'
import { Routes, Route } from "react-router-dom";
import './App.css'
import Main from './Main'
import Rebalance from './Rebalance'

function App() {
  return (
      <Routes>
        <Route path="/" element={<Main />}></Route>
        <Route path="/rebalance" element={<Rebalance />}></Route>
      </Routes>
  )
}

export default App
